import "./banner.scss";

const Banner = () => {
  return (
    <div className="banner">
      <h2>Welcome to XGOL</h2>
      <h4>Your public speaking coach simulator!</h4>
      <div>
        <div className="header-container">
          <img src={`/assets/icons/landing/banner1.svg`} />
          <h3>Unleash Your Potential</h3>
        </div>
        <p>
          Feel the pulse of the audience, hear the applause, and navigate
          through lifelike scenarios that mirror the challenges of real-world
          speaking engagements. The Public Speaking Simulator is more than just
          a tool; it's your dynamic companion, guiding you to articulate ideas
          with confidence, clarity, and charisma.
        </p>
      </div>
      <div>
        <div className="header-container">
          <img src={`/assets/icons/landing/banner2.svg`} />
          <h3>Limitless Environments</h3>
        </div>
        <p>
          From boardrooms to auditoriums, from casual gatherings to grand
          conferences – the simulator transports you to diverse settings,
          ensuring you're prepared for any speaking environment. Tailor your
          experience and conquer the fear of public speaking on your terms.
        </p>
      </div>
      <div>
        <div className="header-container">
          <img src={`/assets/icons/landing/banner3.svg`} />
          <h3>Real-time Feedback:</h3>
        </div>
        <p>
          Witness the magic unfold as our simulator provides instant,
          constructive feedback. Hone your strengths, tackle weaknesses, and
          watch your communication prowess evolve in real-time. Your journey
          towards mastery is guided by insightful analytics and personalized
          coaching.
        </p>
      </div>
    </div>
  );
};

export default Banner;
