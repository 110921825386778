import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import speechSlice from "./features/speech/speechSlice";
import videoSlice from "./features/video/videoSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; //
import adminSlice from "./features/admin/adminSlice";
import dashboardSlice from "./features/dashboard/dashboardSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    speech: speechSlice,
    video: videoSlice,
    admin: adminSlice,
    dashboard: dashboardSlice,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
