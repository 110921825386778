import axios from "axios";
import { backendUrl } from "../../url";

export const getAllReviewsVideos = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(`${backendUrl}/api/videos/expert`, config);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
export const getAllVideos = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(`${backendUrl}/api/videos/client`, config);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};
export const getOneVideo = async (video) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.get(
      `${backendUrl}/api/videos/${video}`,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const recordVideoAction = async (
  { payload, abortController },
  onProgress
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      signal: abortController.signal,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);

        onProgress(percentage);
      },
    };
    const response = await axios.post(
      `${backendUrl}/api/feature/recordvideo`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const requestExpertAction = async (video) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.post(
      `${backendUrl}/api/feature/requestevalution`,
      { video },
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data || error.response.message;
  }
};

export const recordExpertVideoAction = async (data) => {
  const { payload, abortController, onProgress } = data;

  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      signal: abortController.signal,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);

        onProgress(percentage);
      },
    };
    const response = await axios.post(
      `${backendUrl}/api/videos/assess`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitUserReviewService = async (videoId, payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.patch(
      `${backendUrl}/api/videos/${videoId}`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVideoService = async (id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.delete(
      `${backendUrl}/api/videos/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    throw error.response.data.error || error.response.data.message;
  }
};
